import {Button, Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import {Popover} from "@headlessui/react";
import {ChevronDownIcon, ChevronUpIcon, DownloadIcon, SearchIcon} from "@heroicons/react/solid";
import React, {useEffect, useState} from "react";
import {CSVLink} from "react-csv";
import {Row} from "./shared";

const SearchInput = ({onSearch}) => {
  const [search, setSearch] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      onSearch(search);
    }, 300);

    return () => clearTimeout(handler);
  }, [search]);

  return (
    <div className="relative rounded-md shadow-sm w-[48] mb-2">
      <div className="relative flex items-center">
        <SearchIcon className="absolute pl-2 h-5 w-8 text-gray-400" />

        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search..."
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md border py-2 bg-gray-100 text-gray-600"
        />
      </div>
    </div>
  );
};

const FilterOptions = ({option, handleCheck}) => (
  <div
    key={option.value}
    onClick={() => handleCheck(option.value)}
    className="cursor-pointer flex flex-row gap-2 items-center hover:bg-gray-100"
  >
    <input
      type="checkbox"
      checked={option.checked}
      className="h-4 w-4 rounded border-gray-300 text-indigo-600 cursor-pointer"
    />

    <span>{option.label}</span>
  </div>
);

const Filter = ({name, options, onFilter}) => {
  const [checkedOptions, setCheckedOptions] = useState([]);

  useEffect(() => {
    setCheckedOptions(options);
  }, [options]);

  const handleCheck = (value) => {
    setCheckedOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.value === value
          ? {
              ...option,
              checked: !option.checked,
            }
          : option
      )
    );
    onFilter(value);
  };

  return (
    <div className="flex flex-row gap-2 text-sm">
      <Popover.Group>
        <Popover className="relative">
          <Popover.Button className="flex flex-row gap-1 items-center">
            <span className="text-gray-700">{name}</span>

            <ChevronDownIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" />
          </Popover.Button>

          <Popover.Panel className="absolute z-10 mt-2 w-auto right-0 focus:outline-none">
            <div className="rounded-lg shadow-lg overflow-hidden">
              <div className="relative bg-white p-4 whitespace-nowrap">
                {checkedOptions.map((option) => (
                  <FilterOptions option={option} handleCheck={handleCheck} />
                ))}
              </div>
            </div>
          </Popover.Panel>
        </Popover>
      </Popover.Group>
    </div>
  );
};

const FilterRow = ({filters, onFilter}) => {
  return (
    <Row className="gap-2">
      {filters.map(({filterLabel, column, options}) => (
        <Filter
          name={filterLabel}
          options={options}
          onFilter={(value) =>
            onFilter((prevFilters) => {
              const exists = prevFilters.some((f) => f.column === column && f.value === value);
              if (exists) {
                return prevFilters.filter((f) => !(f.column === column && f.value === value));
              }
              return [...prevFilters, {value, column}];
            })
          }
        />
      ))}
    </Row>
  );
};

const ExportButton = ({columns, data, exportFile}) => {
  const csvHeaders = columns.map(({label, key}) => ({label, key}));
  const csvData = data.map((row) => {
    const csvRow = {};
    for (let column of columns) {
      csvRow[column.key] = column.csvFormat
        ? column.csvFormat(row)
        : column.formatRow
        ? column.formatRow(row)
        : column.format
        ? column.format(row[column.key])
        : row[column.key];
    }
    return csvRow;
  });

  return (
    <CSVLink data={csvData} headers={csvHeaders} filename={exportFile + ".csv"}>
      <div className="ml-5 text-sm font-medium flex flex-row items-center cursor-pointer rounded text-indigo-500 hover:text-indigo-700">
        <div className="mr-1">Export</div>

        <DownloadIcon className="h-4 w-4" aria-hidden="true" />
      </div>
    </CSVLink>
  );
};

// Simple helper renders

const Search = ({searchableColumns, setSearch}) =>
  searchableColumns.length > 0 && <SearchInput onSearch={setSearch} />;

const Filters = ({filters, setFilter}) =>
  filters.length > 0 && <FilterRow filters={filters} onFilter={setFilter} />;

const Export = ({columns, data, exportFile}) =>
  exportFile && <ExportButton columns={columns} data={data} exportFile={exportFile} />;

/**
 * SearchableTable Component
 *
 * @param {Object} props - Component props
 * @param {Array<{
 *   label: string,
 *   key: string,
 *   type?: "string" | "number" | "dollars",
 *   format?: (value: any) => any,
 *   formatRow?: (row: any) => any,
 *   onChange?: (row: any, key: string, newValue: any) => void,
 *   filterOptions: Array<{ value: string, label: string }>,
 *   filterFunction?: (row: any, value: string) => boolean,
 *   csvFormat?: (row: any) => any,
 *   headerClassName?: string,
 *   sortable?: boolean
 * }>} props.columns - Configuration for table columns
 * @param {Array<any>} props.data - The data to be displayed in the table
 * @param {(row: any) => void} [props.onRowClick] - Callback when a row is clicked
 * @param {string} [props.className=""] - Additional class names for styling
 * @param {Array<React.ReactNode>} [props.buttons=[]] - Optional buttons to render
 * @param {Array<string>} [props.searchableColumns=[]] - Columns that should be searchable
 * @param {number} [props.rowsPerPage] - Number of rows per page for pagination
 * @param {() => void} [props.exportFile] - Function to export table data
 */
export const SearchableTable = ({
  columns,
  data,
  onRowClick,
  className = "",
  buttons = [],
  searchableColumns = [],
  rowsPerPage,
  exportFile,
}) => {
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState({key: null, direction: null});
  const [filteredData, setFilteredData] = useState(data);

  const filters = columns.reduce((filts, {label, key, filterOptions}) => {
    if (!filterOptions) return filts;
    filts.push({
      filterLabel: label,
      column: key,
      options: filterOptions,
    });
    return filts;
  }, []);

  const filterFunctions = columns.reduce((funcs, {key, filterFunction}) => {
    if (!filterFunction) return funcs;
    funcs[key] = filterFunction;
    return funcs;
  }, {});

  const sortFunction = (a, b) => {
    const aValue = a[sort.key];
    const bValue = b[sort.key];

    if (typeof aValue === "string" && typeof bValue === "string") {
      return sort.direction === "Asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    } else {
      return sort.direction === "Asc" ? aValue - bValue : bValue - aValue;
    }
  };

  // ✅ Compute filtered data **only when search, sort, or filter changes**
  useEffect(() => {
    if (!search && filter.length === 0 && !sort.key) {
      setFilteredData(data);
      return;
    }

    const lowercasedSearch = search.toLowerCase();

    const filtered = data
      .filter((line) => {
        const matchesFilter =
          filter.length > 0 ? filter.some(({column, value}) => filterFunctions[column]?.(line, value)) : true;

        const searchMatch = searchableColumns.some((column) =>
          line[column]?.toLowerCase().includes(lowercasedSearch)
        );
        return matchesFilter && searchMatch;
      })
      .sort(sortFunction);
    setFilteredData(filtered);
  }, [search, sort, filter, data, searchableColumns]);

  return (
    <>
      <div className="border-t-2 my-1 border-gray-200" />

      <Row className="justify-between items-center">
        <Search searchableColumns={searchableColumns} setSearch={setSearch} />

        <Row className="gap-2">
          <Filters filters={filters} setFilter={setFilter} />
          <Export columns={columns} data={data} exportFile={exportFile} />
        </Row>
      </Row>

      {!data.length ? (
        <Loading />
      ) : (
        <Table
          columns={columns}
          data={filteredData}
          onRowClick={onRowClick}
          className={className}
          buttons={buttons}
          rowsPerPage={rowsPerPage}
          setSort={setSort}
        />
      )}
    </>
  );
};

const ColumnHeader = ({column, setSort, setSorted, sorted, headerClassName}) => {
  const {key, label, sortable} = column;
  const isSorted = sorted.key === key;
  const SortIcon = isSorted && sorted.direction === "Asc" ? ChevronUpIcon : ChevronDownIcon;
  const otherDirection = sorted.direction === "Asc" ? "Desc" : "Asc";

  return (
    <th className={`pl-4 text-left text-gray-500 py-4 text-xs font-medium ${headerClassName}`} key={label}>
      <Row className="gap-2">
        {label.toUpperCase()}

        {sortable && (
          <SortIcon
            className={`h-4 w-4 ${isSorted ? "text-gray-500" : "text-gray-400"}`}
            onClick={() => {
              const direction = isSorted ? otherDirection : "Desc";
              setSorted({key, direction});
              setSort({key, direction});
            }}
          />
        )}
      </Row>
    </th>
  );
};

const TableHeader = ({columns, setSort, buttons}) => {
  const [sorted, setSorted] = useState({key: null, direction: null});
  const hasButtons = buttons?.length > 0;
  const buttonHeaders = buttons?.map((_, buttonIdx) => (
    <th className={"w-12"} key={`buttons-${buttonIdx}`} />
  ));

  return (
    <thead className="bg-gray-50 rounded-lg">
      <tr className="rounded-lg">
        {columns.map((column) => {
          const {key, label, headerClassName = ""} = column;

          if (!label) return <th className={`pl-4 ${headerClassName}`} key={key} />;

          return (
            <ColumnHeader
              column={column}
              setSort={setSort}
              setSorted={setSorted}
              sorted={sorted}
              headerClassName={headerClassName}
            />
          );
        })}
        {hasButtons && buttonHeaders}
      </tr>
    </thead>
  );
};

const EditableCell = ({value, type = "string", format, isEditing, onChange, setEditing}) => {
  const [editingValue, setEditingValue] = useState(value);

  const maskInput = (value) => {
    if (type === "number") {
      return value.replace(/[^0-9]/g, "");
    }
    if (type === "dollars") {
      return value
        .replace(/[^0-9.]/g, "") // Remove non-numeric and non-dot characters
        .replace(/^(\d*\.)(.*)\./g, "$1$2");
    }
    return value;
  };

  const handleChange = (e) => {
    const newValue = maskInput(e.target.value);
    setEditingValue(newValue);
  };
  return (
    <input
      className={`border-none focus:outline-none w-full text-sm`}
      type={"text"}
      value={isEditing ? editingValue : format(value)}
      onBlur={() => {
        setEditing(false);
        onChange(editingValue);
      }}
      onFocus={() => setEditing(true)}
      onChange={handleChange}
    />
  );
};

const TableRow = ({columns, line, onClick, buttons, active, setActive}) => {
  const buttonsToRender = buttons?.map(({onClick, label}, buttonIdx) => (
    <td className={"text-center border-b border-gray-200 text-sm py-1"} key={`buttons-${buttonIdx}`}>
      <button
        key={buttonIdx}
        onClick={() => onClick(line)}
        className="pr-4 text-indigo-600 hover:text-indigo-900 text-sm font-medium"
      >
        {label}
      </button>
    </td>
  ));

  return (
    <tr className={onClick ? "cursor-pointer" : ""} onClick={onClick ? () => onClick(line) : undefined}>
      {columns.map(({key, type, format = (it) => it, formatRow, onChange = undefined}, idx) => {
        return (
          <td className={`pl-4 text-left border-b border-gray-200 text-sm py-1`} key={key}>
            {onChange ? (
              <EditableCell
                value={line[key]}
                type={type}
                format={formatRow ? (val) => formatRow(line) : format}
                isEditing={active._tableRowId === line.UNIQUE_ID && active._tableColumnKey === key}
                setEditing={(editing) => (editing ? setActive(line.UNIQUE_ID, key) : setActive())}
                onChange={(newValue) => onChange(line, key, newValue)}
              />
            ) : (
              <span className="cursor-default text-gray-500">
                {formatRow ? formatRow(line) : format(line[key])}
              </span>
            )}
          </td>
        );
      })}
      {buttonsToRender}
    </tr>
  );
};

const Pagination = ({data, rowsPerPage, page, setPage, pageSize, setPageSize}) => (
  <Row className="justify-between items-center p-2">
    <Row className="gap-2 text-sm text-indigo-600 cursor-default">
      Rows Per Page:
      {rowsPerPage.map((size, index) => (
        <span onClick={() => setPageSize(size)}>
          {size === pageSize && <span className="font-medium">({size})</span>}
          {size !== pageSize && <span className="cursor-pointer font-medium">{size}</span>}
        </span>
      ))}
    </Row>

    <p className="text-sm text-gray-700">
      Showing
      <span className="font-medium"> {pageSize * page - pageSize + 1} </span>
      to
      <span className="font-medium"> {pageSize * page} </span>
      of
      <span className="font-medium"> {data.length} </span>
      results
    </p>

    <Row className="gap-2">
      <Button label="Previous" onClick={() => setPage((prevPage) => prevPage - 1)} disabled={page === 1} />

      <Button
        label="Next"
        onClick={() => setPage((prevPage) => prevPage + 1)}
        disabled={page === Math.ceil(data.length / pageSize)}
      />
    </Row>
  </Row>
);

export const Table = ({
  columns,
  data,
  onRowClick,
  className = "",
  buttons = [],
  rowsPerPage = [10, 25, 50],
  setSort,
}) => {
  const [active, setActive] = useState({_tableRowId: null, _tableColumnKey: null});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(rowsPerPage[0]);
  const [tableData, setData] = useState([]);

  useEffect(() => {
    setPage(1);
  }, [pageSize, data]);

  useEffect(() => {
    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    setData(data.slice(start, end));
  }, [page, pageSize, data]);

  return (
    <div className={`bg-white ${className} rounded-lg`}>
      <table className="min-w-full rounded-lg">
        <TableHeader columns={columns} setSort={setSort} buttons={buttons} />

        <tbody className="">
          {tableData.map((dataLine) => {
            return (
              <TableRow
                key={dataLine.id || JSON.stringify(dataLine)}
                columns={columns}
                line={dataLine}
                onClick={onRowClick}
                buttons={buttons}
                active={active}
                setActive={(rowId, columnKey) => {
                  setActive({_tableRowId: rowId, _tableColumnKey: columnKey});
                }}
              />
            );
          })}
        </tbody>
      </table>

      <Pagination
        data={data}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </div>
  );
};
