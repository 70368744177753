import React, {Component} from "react";
import {Card, Loading, PageHeadings, Table, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import BankAccountModal from "../../../modals/banking/bank-account-modal";
import {request} from "../../../utils/request";
import DangerBadge from "../../../components/badges/danger-badge";
import OtherPayoutSettingsModal from "../../../modals/banking/other-payout-settings-modal";
import CreateStripeLoadingModal from "../../../modals/finance/create-stripe-loading-modal";
import EmailModal from "../../../modals/general/email-modal";
import TaxWithholdingSettingsModal from "../../../modals/banking/tax-withholding-settings-modal";

class PayoutSettingsPage extends Component {
  state = {
    loading: true,
    stripeAccount: null,
    isStripeAccountComplete: null,
    bankAccount: null,
    taxWithholdingEnabled: false,
    taxWithholding: null,
  };

  async componentDidMount() {
    await this.refresh();
  }

  refresh = async () => {
    const [{stripeAccount, isStripeAccountComplete, bankAccount}, taxWithholding] = await Promise.all([
      request("stripe/payouts/settings", "GET"),
      request("tax-withholding", "GET", null),
    ]);

    this.setState({
      loading: false,
      stripeAccount,
      isStripeAccountComplete,
      bankAccount,
      taxWithholding,
    });
  };

  async openSettingsLink() {
    let {stripeAccount} = this.state;

    if (!stripeAccount) {
      this.createStripeLoadingModal.open();

      await request("stripe/connect/partner", "POST").then((data) => {
        window.open(data.url, "_self").focus();
      });
    } else {
      request("stripe/connect/partner", "GET", null).then((data) => {
        window.open(data, "_self").focus();
      });
    }
  }

  render() {
    let {loading, stripeAccount, isStripeAccountComplete, bankAccount} = this.state;
    const {account} = this.props.user;
    const NEED_EMAIL = !account.EMAIL || account.EMAIL.length === 0;

    let {PAYOUT_STATEMENT_DESCRIPTOR} = this.props.shop.settings;

    let statementDescriptor = stripeAccount?.settings?.payments?.statement_descriptor;

    const {TAX_WITHHOLDING} = this.props.shop.settings;

    if (loading) {
      return <Loading />;
    }

    let stripeStr = stripeAccount
      ? "Open Stripe Settings"
      : NEED_EMAIL
      ? "Please Add an Email"
      : "Create Stripe Account";
    let bankStr = bankAccount ? "Change Bank Account" : "Add Bank Account";
    let stripeAccountBadgeStr = !stripeAccount ? "Not Started" : "Incomplete";

    let bankAccButton = isStripeAccountComplete
      ? [
          {
            label: bankStr,
            onClick: () => this.bankAccountModal.open(),
          },
        ]
      : [];

    return (
      <div>
        <CreateStripeLoadingModal ref={(e) => (this.createStripeLoadingModal = e)} />

        <BankAccountModal ref={(e) => (this.bankAccountModal = e)} refresh={this.refresh} />

        <EmailModal
          ref={(e) => (this.emailModal = e)}
          refresh={(EMAIL) => {
            this.props.updateUserAccount({...account, EMAIL});
            this.refresh();
          }}
          account={account}
        />

        <OtherPayoutSettingsModal ref={(e) => (this.otherPayoutSettingsModal = e)} refresh={this.refresh} />

        <TaxWithholdingSettingsModal ref={(e) => (this.taxWithholdingModal = e)} />

        <PageHeadings label="Payout Settings" />

        <Card
          label={
            <div>
              {"Stripe Account"}
              {!isStripeAccountComplete && (
                <DangerBadge className={"ml-4"}>{stripeAccountBadgeStr}</DangerBadge>
              )}
            </div>
          }
          buttons={[
            {
              label: stripeStr,
              disabled: stripeAccount ? false : NEED_EMAIL,
              onClick: () => this.openSettingsLink(),
            },
          ]}
        />

        {stripeAccount && (
          <Card
            label={
              <div>
                {"Bank Account"}
                {!bankAccount && (
                  <DangerBadge className={"ml-4"}>
                    {isStripeAccountComplete ? `Incomplete` : "Complete Stripe Onboarding First"}
                  </DangerBadge>
                )}
              </div>
            }
            buttons={bankAccButton}
          >
            {bankAccount && (
              <Table
                data={bankAccount}
                columns={[
                  {
                    label: "Bank Name",
                    value: "bankName",
                  },
                  {
                    label: "Account Number",
                    value: "accountNumber",
                  },
                  {
                    label: "Routing Number",
                    value: "routingNumber",
                  },
                ]}
              />
            )}
          </Card>
        )}

        {stripeAccount && (
          <TwoColumnList
            label={"Payout Descriptor"}
            buttons={[
              {
                label: "Edit",
                onClick: () => this.otherPayoutSettingsModal.open(stripeAccount),
              },
            ]}
            data={[
              {
                label: "Payment Statement Descriptor",
                value: statementDescriptor,
              },
              {
                label: "Payout Statement Descriptor",
                value: PAYOUT_STATEMENT_DESCRIPTOR,
                tooltip:
                  "The description that will appear on your bank statement next to the payout. Some banks will display this information incorrectly or not at all.",
              },
            ]}
          />
        )}

        <TwoColumnList
          label={"Tax Withholding"}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.taxWithholdingModal.open(),
            },
          ]}
          data={[
            {
              label: "Withhold Tax from Payouts",
              type: "bool",
              value: TAX_WITHHOLDING,
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["banking", "user", "shop"])(withRouter(PayoutSettingsPage));
