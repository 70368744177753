import React, { Component } from "react";
import {
    CELL_TYPES,
    PAYMENT_METHODS_COLUMNS,
} from "../../features/reporting/reports/reporting-constants";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import { setupReduxConnection } from "../../redux";
import { PageHeadings } from "@frostbyte-technologies/frostbyte-tailwind";
import {
    AUDIT_PAYMENT_METHODS_COLUMNS, PAYMENT_METHODS_ROWS
} from "../../features/reporting/reports/audit-reporting-constants";


class PaymentMethodsReportPage extends Component {
    filterZeroRows = (rows, reportData) => {
        return rows.filter(({ selector, filterZeros }) => {
            if (!filterZeros) return true;
            const value = reportData.PAYMENT_METHOD_RECORDS?.[selector]?.NET_TOTAL || 0;
            return value !== 0;
        });
    };

    getCustomTypeRows = (reportData) => {
        return Object.keys(reportData.CUSTOM_TYPE_BREAKDOWN || {}).map((key) => ({
            label: reportData.CUSTOM_TYPE_BREAKDOWN[key].PAYMENT_TYPE,
            numbers: reportData.CUSTOM_TYPE_BREAKDOWN[key],
            style: [CELL_TYPES.SECONDARY],
        }));
    };

    getCustomTypeTotalRow = (reportData) => {
        const totals = Object.keys(reportData.CUSTOM_TYPE_BREAKDOWN || {}).reduce(
            (acc, key) => {
                const item = reportData.CUSTOM_TYPE_BREAKDOWN[key];
                Object.keys(acc).forEach((k) => (acc[k] += item[k] || 0));
                return acc;
            },
            {
                NET_PAYMENT: 0,
                NET_TOTAL: 0,
                PAYMENT_COUNT: 0,
                PROCESSING_FEES: 0,
                REFUND_AMOUNT: 0,
                TOTAL_COLLECTED: 0,
            }
        );
        return { label: "Custom Types", numbers: totals };
    };

    convertDataToRows = (reportData) => {
        if (!reportData) return [];

        let rows = PAYMENT_METHODS_ROWS.map(({ label, selector, style, filterZeros }) => ({
            label,
            numbers: reportData.PAYMENT_METHOD_RECORDS?.[selector] || {},
            style,
            filterZeros,
        }));

        const customTotals = this.getCustomTypeTotalRow(reportData);

        if (Object.values(customTotals.numbers).some((val) => val !== 0)){
            rows.push(this.getCustomTypeTotalRow(reportData));
            rows.push(...this.getCustomTypeRows(reportData));
        }

        rows = this.filterZeroRows(rows, reportData);
        rows.push({
            label: "All Payments", numbers: reportData.TOTALS, style: [CELL_TYPES.BOLD]
        })
        return rows;
    };

    render() {
        const { REPORTING_MODE, REPORTING_SHOW_LOCATION_CSV, REPORTING_SHOW_DATE_CSV } = this.props.shop.settings;
        let columns = REPORTING_MODE === "AUDIT" ? AUDIT_PAYMENT_METHODS_COLUMNS : PAYMENT_METHODS_COLUMNS;

        return (
            <div>
                <div className="mb-5">
                    <PageHeadings label={"Payment Methods Report"} />
                </div>
                <FixedColumnReport
                    filenamePrefix="payment-methods"
                    endpoint="report/paymentmethods"
                    constantColumns={columns}
                    convertDataToRows={this.convertDataToRows}
                    locationPicker={true}
                    firstColLabel="Payment Method"
                    showLocationsInDownload={REPORTING_SHOW_LOCATION_CSV === "1"}
                    showDateRangeInDownload={REPORTING_SHOW_DATE_CSV === "1"}
                    enableStripePayouts={true}
                />
            </div>
        );
    }
}

export default setupReduxConnection(["reporting", "shop"])(PaymentMethodsReportPage);
