import {CELL_TYPES} from "./reporting-constants";

export const AUDIT_SALES_SUMMARY_ROWS = [
  {
    label: "Gross Sales",
    selector: "GROSS_SALES",
    rowType: CELL_TYPES.BOLD,
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Product Sales",
    selector: "PRODUCT_SALES",
    dollarAmount: true,
    secondary: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Service Fees",
    selector: "SERVICE_FEES_COLLECTED",
    dollarAmount: true,
    secondary: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Custom Fees",
    selector: "CUSTOM_FEES_COLLECTED",
    dollarAmount: true,
    secondary: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Pass Processing Collected",
    selector: "PASS_PROCESSING_COLLECTED",
    dollarAmount: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Refunds",
    selector: "REFUNDS",
    rowType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    negative: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Discounts",
    selector: "DISCOUNTS",
    rowType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    negative: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Sales",
    selector: "NET_SALES",
    rowType: CELL_TYPES.BOLD,
    dollarAmount: true,
    bold: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Tips",
    selector: "TIPS",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Card Tips",
    selector: "CARD_TIPS",
    rowType: CELL_TYPES.SECONDARY,
    dollarAmount: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Cash Tips",
    selector: "CASH_TIPS",
    rowType: CELL_TYPES.SECONDARY,
    dollarAmount: true,
    style: [CELL_TYPES.SECONDARY],
  },
  {
    label: "Third Party Tips",
    selector: "THIRD_PARTY_TIPS",
    rowType: CELL_TYPES.SECONDARY,
    dollarAmount: true,
    style: [CELL_TYPES.SECONDARY],
    tooltip: "Tips collected by a third party using Stream. This does not count towards the total tip pool.",
  },
  {
    label: "Gift Cards",
    selector: "GIFT_CARDS",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Delivery Fees",
    selector: "DELIVERY_FEES_COLLECTED",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Tax",
    selector: "TAXES",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Fundraiser",
    selector: "FUNDRAISER",
    dollarAmount: true,
    secondary: true,
    style: [CELL_TYPES.REGULAR],
  },
  {
    label: "Total Sales",
    selector: "NET_PAYMENT",
    type: CELL_TYPES.BOLD,
    dollarAmount: true,
    bold: true,
    style: [CELL_TYPES.BOLD],
  },
  {
    label: "Processing",
    selector: "PROCESSING_FEES",
    rowType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    negative: true,
    style: [CELL_TYPES.NEGATIVE],
  },
  {
    label: "Net Total",
    selector: "NET_TOTAL",
    rowType: CELL_TYPES.BOLD,
    dollarAmount: true,
    bold: true,
    style: [CELL_TYPES.BOLD],
  },
];

export const AUDIT_PAYMENT_METHODS_COLUMNS = [
  {
    label: "Total Collected",
    columnSelector: "TOTAL_COLLECTED",
    columnType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [],
    tooltip:
      "This value represents the total amount of sales collected after taking taxes, collected fees, tips, and discounts into account.",
  },
  {
    label: "(Refund Amount)",
    columnSelector: "REFUND_AMOUNT",
    columnType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
    tooltip:
      "This value represents the total amount that was refunded from sales made during your selected time period.",
  },
  {
    label: "Net Payment",
    columnSelector: "NET_PAYMENT",
    columnType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [],
    tooltip: "This value represents the total amount of sales collected after taking refunds into account.",
  },
  {
    label: "(Processing)",
    columnSelector: "PROCESSING_FEES",
    columnType: CELL_TYPES.NEGATIVE,
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
    tooltip: "This value represents the total amount of fees collected from processing.",
  },
  {
    label: "Net Total",
    columnSelector: "NET_TOTAL",
    columnType: CELL_TYPES.BOLD,
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
    tooltip:
      "This value represents the total amount of sales collected after taking processing fees into account.",
  },
];
export const PAYMENT_METHODS_ROWS = [
  { label: "Cards", selector: "CARDS", style: [CELL_TYPES.REGULAR] },
  { label: "Card Present", selector: "CARD_PRESENT", style: [CELL_TYPES.SECONDARY], filterZeros: true },
  { label: "Card Not Present", selector: "CARD_NOT_PRESENT", style: [CELL_TYPES.SECONDARY], filterZeros: true },
  { label: "Cash", selector: "CASH", style: [CELL_TYPES.REGULAR] },
  { label: "Check", selector: "CHECK", style: [CELL_TYPES.REGULAR], filterZeros: true },
  { label: "Received ACH", selector: "US_BANK", style: [CELL_TYPES.REGULAR], filterZeros: true },
  { label: "Loan Paydown", selector: "PAYDOWN", style: [CELL_TYPES.SECONDARY], filterZeros: true },
  { label: "Gift Cards", selector: "GIFT_CARDS", style: [CELL_TYPES.REGULAR] },
  { label: "Digital Cards", selector: "GIFT_CARD", style: [CELL_TYPES.SECONDARY] },
  { label: "Physical Cards", selector: "E_GIFT_CARD", style: [CELL_TYPES.SECONDARY] },
  { label: "Loyalty Rewards", selector: "REWARD", style: [CELL_TYPES.REGULAR], filterZeros: true },
  { label: "Net Accounts Receivable", selector: "NET_AR", style: [CELL_TYPES.REGULAR], filterZeros: true },
  { label: "Billing Accounts", selector: "BILLING", style: [CELL_TYPES.SECONDARY], filterZeros: true },
  { label: "Invoicing", selector: "INVOICE", style: [CELL_TYPES.SECONDARY], filterZeros: true },
  { label: "Payout Fees", selector: "BANK_PAYOUT", style: [CELL_TYPES.SECONDARY], filterZeros: true },
];

export const MINI_PAYMENT_METHODS_ROWS = [
  { label: "Cards", selector: "CARDS", style: [CELL_TYPES.REGULAR] },
  { label: "Cash", selector: "CASH", style: [CELL_TYPES.REGULAR]},
  { label: "Gift Cards", selector: "GIFT_CARDS", style: [CELL_TYPES.REGULAR], filterZeros: true},
  { label: "Loyalty Rewards", selector: "REWARD", style: [CELL_TYPES.REGULAR], filterZeros: true },
  { label: "Net Accounts Receivable", selector: "NET_AR", style: [CELL_TYPES.REGULAR], filterZeros: true },
  { label: "Check", selector: "CHECK", style: [CELL_TYPES.REGULAR],  filterZeros: true },
];

export const AUDIT_PRODUCT_SALES_COLUMNS = [
  {label: "Category", columnSelector: "CATEGORY_NAME"},
  {
    label: "Products Sold",
    columnSelector: "ORDER_COUNT",
    tooltip:
      "The value under this column represents the total amount of times that product was sold during your selected time period.",
  },
  {
    label: "Gross Sales",
    columnSelector: "GROSS_SALES",
    dollarAmount: true,
    columnType: CELL_TYPES.BOLD,
    style: [CELL_TYPES.BOLD],
    tooltip:
      "The value under this column represents the total amount of sales collected from that product, without accounting for taxes, refunds, or discounts.",
  },
  {
    label: "Estimated Cost",
    columnSelector: "ESTIMATED_COST",
    dollarAmount: true,
    columnType: CELL_TYPES.BOLD,
    style: [CELL_TYPES.BOLD],
    tooltip:
      "This represents the estimated cost of this product. This is based on the entered cost of the product during product pricing.",
  },
  {
    label: "(Refunds)",
    columnSelector: "REFUNDS",
    dollarAmount: true,
    columnType: CELL_TYPES.NEGATIVE,
    style: [CELL_TYPES.NEGATIVE],
    tooltip:
      "The value under this column represents the total amount of refunds given for the product during your selected time period.",
  },
  {
    label: "(Discounts)",
    columnSelector: "DISCOUNTS",
    dollarAmount: true,
    columnType: CELL_TYPES.NEGATIVE,
    style: [CELL_TYPES.NEGATIVE],
    tooltip:
      "The value under this column represents the total amount that was discounted from sales of that specific product during your selected time period.",
  },
  {
    label: "Net Sales",
    columnSelector: "NET_SALES",
    dollarAmount: true,
    columnType: CELL_TYPES.BOLD,
    style: [CELL_TYPES.BOLD],
    tooltip:
      "The value under this column represents the total amount of sales collected from that product, after taking discounts into account.",
  },
  {
    label: "Tax",
    columnSelector: "TAXES",
    dollarAmount: true,
    style: [],
    tooltip:
      "The value under this column represents the total amount of taxable sales collected from purchases of the product.",
  },
];

export const AUDIT_CATEGORY_SALES_COLUMNS = [
  {
    label: "Quantity Sold",
    columnSelector: "ORDER_COUNT",
    tooltip:
      "The value under this column represents the total number of times that a product from that category was sold during your selected time period.",
  },
  {
    label: "Gross Sales",
    columnSelector: "GROSS_SALES",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
    tooltip:
      "The value under this column represents the total amount of sales collected from products under that category, without accounting for taxes, refunds, or discounts.",
  },
  {
    label: "(Refunds)",
    columnSelector: "REFUNDS",
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
    tooltip:
      "The value under this column represents the total amount that was refunded from sales of products under that category during your selected time period.",
  },
  {
    label: "(Discounts)",
    columnSelector: "DISCOUNTS",
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
    tooltip:
      "The value under this column represents the total amount that was discounted from sales of products under that category during your selected time period.",
  },
  {
    label: "Net Sales",
    columnSelector: "NET_SALES",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
    tooltip:
      "The value under this column represents the total amount of sales collected from products under that category after discounts are taken into account.",
  },
  {
    label: "Tax",
    columnSelector: "TAXES",
    dollarAmount: true,
    tooltip:
      "The value under this column represents the total amount of taxes collected from sales of products under that category during your selected time period.",
  },
];

export const AUDIT_PLATFORM_SALES_COLUMNS = [
  {
    label: "Order Count",
    columnSelector: "PAYMENT_COUNT",
    tooltip:
      "The value under this column represents the total amount of orders placed through the corresponding ordering platform within the specified time frame.",
  },
  {
    label: "Gross Sales",
    columnSelector: "GROSS_SALES",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
    tooltip:
      "The value under this column represents the total amount of sales collected from orders placed through the corresponding ordering platform, excluding taxes, services fees, custom fees, refunds, and/or discounts.",
  },
  {
    label: "Product Sales",
    columnSelector: "PRODUCT_SALES",
    dollarAmount: true,
    secondary: true,
    style: [CELL_TYPES.SECONDARY],
    tooltip:
      "The value under this column represents the total amount of sales collected from orders placed through the corresponding ordering platform, including services fees, custom fees, and/or discounts. The value listed does not include taxes or refunds.",
  },
  {
    label: "Service Fees",
    columnSelector: "SERVICE_FEES_COLLECTED",
    dollarAmount: true,
    secondary: true,
    style: [CELL_TYPES.SECONDARY],
    tooltip:
      "The value under this column represents the total amount of fees collected on the corresponding ordering platform from customers through service fees, delivery fees, and passed on processing fees.",
  },
  {
    label: "Custom Fees",
    columnSelector: "CUSTOM_FEES_COLLECTED",
    dollarAmount: true,
    secondary: true,
    style: [CELL_TYPES.SECONDARY],
    tooltip:
      "The value under this column represents the total amount of fees collected on the corresponding ordering platform from customers through shop custom fees.",
  },
  {
    label: "(Refunds)",
    columnSelector: "REFUNDS",
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
    tooltip:
      "The value under this column represents the total amount that was refunded from sales made through the corresponding ordering platform during your selected time period.",
  },
  {
    label: "(Discounts)",
    columnSelector: "DISCOUNTS",
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
    tooltip:
      "The value under this column represents the total amount that was discounted from sales placed through the corresponding ordering platform during your selected time period.",
  },
  {
    label: "Net Sales",
    columnSelector: "NET_SALES",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
    tooltip:
      "The value under this column represents the total amount of sales collected after accounting for refunds and discounts.",
  },
  {
    label: "Card Tips",
    columnSelector: "CARD_TIPS",
    dollarAmount: true,
    tooltip:
      "The value under this column represents the total amount of credit card tips collected through orders placed under the corresponding order type during your selected time period.",
  },
  {
    label: "Tax",
    columnSelector: "TAXES",
    dollarAmount: true,
    tooltip:
      "The value under this column represents the total amount of taxes collected from orders placed under the corresponding order type during your selected time period.",
  },
  {
    label: "Gift Card Sales",
    columnSelector: "GIFT_CARDS",
    dollarAmount: true,
    tooltip:
      "The value under this column represents the total amount of sales collected from gift cards during your selected time period.",
  },
  {
    label: "Pass Processing Collected",
    columnSelector: "PASS_PROCESSING_COLLECTED",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
    tooltip:
      "The value under this column represents the total amount of pass processing fees (if enabled) collected through orders placed under the corresponding order type during your selected time period.",
  },
  {
    label: "Total Sales",
    columnSelector: "NET_PAYMENT",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
    tooltip:
      "The value under this column represents the total amount of sales collected from orders made under the corresponding order type after taking taxes and discounts into account.",
  },
  {
    label: "(Processing)",
    columnSelector: "PROCESSING_FEES",
    dollarAmount: true,
    style: [CELL_TYPES.NEGATIVE],
    tooltip:
      "The value under this column represents the total amount of processing fees paid through orders placed under the corresponding order type during your selected time period.",
  },
  {
    label: "Net Total",
    columnSelector: "NET_TOTAL",
    dollarAmount: true,
    style: [CELL_TYPES.BOLD],
    tooltip:
      "The value under this column will represent the total amount of sales collected after processing fees have been deducted.",
  },
];

export const AUDIT_GIFT_CARD_SALES_COLUMNS = [
  {
    label: "Quantity Sold",
    columnSelector: "ORDER_COUNT",
    tooltip:
      "The value under this column represents the total amount of gift cards purchased during your selected time period.",
  },
  {
    label: "Gift Cards",
    columnSelector: "GIFT_CARDS",
    rowType: CELL_TYPES.REGULAR,
    dollarAmount: true,
    style: [CELL_TYPES.REGULAR],
    tooltip:
      "The value under this column represents the total amount of sales made through gift card purchases during your selected time period.",
  },
];
