import {Component} from "react";

class TestBanner extends Component {
  render() {
    return (
      <div className="flex justify-center text-center z-50 border-t-2 border-orange-400 absolute w-full">
        <div className="z-50 bg-orange-400 text-white text-xs px-2 rounded-b-md">TEST DATA</div>
      </div>
    );
  }
}

export default TestBanner;
